import { on } from 'delegated-events';
import whenReady from '../../../javascripts/utils/when-ready';
import elementIndex from '../../../javascripts/utils/element-index';

const movePersonSlider = ($slider, value = 0, relative = true) => {
  const currentSlide = parseInt($slider.dataset.currentSlide, 10) || 1;
  const $$slide = $slider.querySelectorAll('.person-slider__slide');
  let nextSlide = relative ? currentSlide + value : value;

  // Out of range
  if (nextSlide === 0) {
    return;
  }

  // Max scroll left
  const $track = $slider.querySelector('.person-slider__slides');
  const maxScrollLeft = $track.scrollWidth - $track.clientWidth + 1;

  // Jump to first slide
  if (
    nextSlide > $$slide.length ||
    maxScrollLeft < $$slide[nextSlide - 1].offsetLeft
  ) {
    nextSlide = 1;
  }

  // Update scroll track
  $track.scrollLeft = nextSlide === 1 ? 0 : $$slide[nextSlide - 1].offsetLeft;
  $slider.dataset.currentSlide = nextSlide; // eslint-disable-line no-param-reassign

  // Update buttons
  const $prevButton = $slider.querySelector(
    '.person-slider__navigation-button--prev',
  );

  $prevButton.disabled = nextSlide === 1;
};

whenReady().then(() => {
  const $$track = document.querySelectorAll('.person-slider__slides');
  $$track.forEach(($track) => {
    // eslint-disable-next-line no-param-reassign
    $track.scrollLeft = 0;
  });
});

window.addEventListener(
  'resize',
  () => {
    const $$slider = document.querySelectorAll('.person-slider');
    $$slider.forEach(($slider) => movePersonSlider($slider));
  },
  false,
);

on('click', '.person-slider__navigation-button--prev', (event) => {
  const $slider = event.target.closest('.person-slider');
  movePersonSlider($slider, -1);
});

on('click', '.person-slider__navigation-button--next', (event) => {
  const $slider = event.target.closest('.person-slider');
  movePersonSlider($slider, 1);
});

on('mousedown', '.person-slider .headline__link', (event) => {
  event.preventDefault();
});

on('focusin', '.person-slider__slide', (event) => {
  const $slide = event.target.closest('.person-slider__slide');
  const $slider = $slide.closest('.person-slider');
  const value = elementIndex($slide);

  movePersonSlider($slider, value + 1, false);
});
