/* eslint-disable no-underscore-dangle */
import cookies from 'js-cookie';
import { on } from 'delegated-events';
import invisibleFocus from '../../../javascripts/utils/invisible-focus';
import whenReady from '../../../javascripts/utils/when-ready';

const cookieOptions = { expires: 365, sameSite: 'Strict', secure: true };
const cookieName = 'analytics_consent';

let analyticsLoaded = false;

const loadAnalytics = () => {
  if (analyticsLoaded) {
    return;
  }

  window.ga =
    window.ga ||
    ((...args) => {
      (window.ga.q = window.ga.q || []).push(args);
    });

  window.ga.l = +new Date();
  window.ga('create', 'UA-189467020-1', 'auto');
  window.ga('set', 'anonymizeIp', true);
  window.ga('send', 'pageview');

  const gascript = document.createElement('script');
  gascript.async = true;
  gascript.src = 'https://www.google-analytics.com/analytics.js';

  document
    .getElementsByTagName('head')[0]
    .appendChild(gascript, document.getElementsByTagName('head')[0]);

  analyticsLoaded = true;
};

const hasUserAgreed = cookies.get(cookieName);

if (hasUserAgreed === '1') {
  loadAnalytics();
} else if (hasUserAgreed !== '0') {
  whenReady().then(() => {
    const $consentBanner = document.querySelector('.consent-banner');
    $consentBanner.hidden = false;
  });
}

on('click', '.js-agree-analytics', (event) => {
  const $consentBanner = event.target.closest('.consent-banner');
  cookies.set(cookieName, '1', cookieOptions);
  $consentBanner.hidden = true;
  loadAnalytics();
});

on('click', '.js-disagree-analytics', (event) => {
  const $consentBanner = event.target.closest('.consent-banner');
  cookies.set(cookieName, '0', cookieOptions);
  $consentBanner.hidden = true;
});

on('click', '.js-show-consent-banner', () => {
  const $consentBanner = document.querySelector('.consent-banner');
  $consentBanner.hidden = false;
  invisibleFocus($consentBanner);
});
