import { on } from 'delegated-events';
import whenReady from '../../../javascripts/utils/when-ready';
import elementIndex from '../../../javascripts/utils/element-index';

const moveStage = ($slider, value = 0, relative = true) => {
  const currentSlide = parseInt($slider.dataset.currentSlide, 10) || 1;
  const $$slide = $slider.querySelectorAll('.stage__slide');
  const nextSlide = relative ? currentSlide + value : value;

  // Out of range
  if (nextSlide === 0 || nextSlide > $$slide.length || $$slide.length === 1) {
    return;
  }

  // Update scroll track
  const $track = $slider.querySelector('.stage__slides');
  const $slide = $$slide[nextSlide - 1];
  $track.scrollLeft = $slide.offsetLeft;
  $slider.dataset.currentSlide = nextSlide; // eslint-disable-line no-param-reassign

  // Update buttons
  const $prevButton = $slider.querySelector('.stage__navigation-button--prev');
  const $nextButton = $slider.querySelector('.stage__navigation-button--next');

  $prevButton.disabled = nextSlide === 1;
  $nextButton.disabled = nextSlide === $$slide.length;

  // Update indicators
  const $$indicator = $slider.querySelectorAll('.stage__navigation-indicator');
  $$indicator.forEach(($indicator, index) => {
    // eslint-disable-next-line no-param-reassign
    $indicator.disabled = index + 1 === nextSlide;
  });
};

whenReady().then(() => {
  const $$track = document.querySelectorAll('.stage__slides');
  $$track.forEach(($track) => {
    // eslint-disable-next-line no-param-reassign
    $track.scrollLeft = 0;
  });
});

window.addEventListener(
  'resize',
  () => {
    const $$slider = document.querySelectorAll('.stage');
    $$slider.forEach(($slider) => moveStage($slider));
  },
  false,
);

on('click', '.stage__navigation-button--prev', (event) => {
  const $slider = event.target.closest('.stage');
  moveStage($slider, -1);
});

on('click', '.stage__navigation-button--next', (event) => {
  const $slider = event.target.closest('.stage');
  moveStage($slider, 1);
});

on('click', '.stage__navigation-indicator', (event) => {
  const $indicator = event.target.closest('.stage__navigation-indicator');
  const $slider = $indicator.closest('.stage');
  const value = parseInt($indicator.dataset.index, 10);

  moveStage($slider, value, false);
});

on('mousedown', '.stage__slide .more-button', (event) => {
  event.preventDefault();
});

on('focusin', '.stage__slide', (event) => {
  const $slide = event.target.closest('.stage__slide');
  const $slider = $slide.closest('.stage');
  const value = elementIndex($slide);

  moveStage($slider, value + 1, false);
});
